
import { Header, Footer } from '../components/layout'

function isBlank(str) {
    return !str || /^\s*$/.test(str)
}

export default {
    components: {
        Header,
        Footer,
    },
    data() {
        return {
            class: '',
        }
    },
    mounted() {
        // const vm = this
        this.$bus.$on('on-popup-open', (flag) => {
            if (flag) {
                // vm.class = 'modal-open'
                document.body.classList.add('modal-open')
            } else {
                // vm.class = ''
                document.body.classList.remove('modal-open')
            }
        })
    },
    destroyed() {
        this.$bus.$off('on-popup-open')
    },
    head() {
        const st = this.$store.state
        return {
            htmlAttrs: {
                lang: 'th',
            },
            title: !isBlank(st.seo.title) ? st.seo.title : 'Propdeal',
            link: [
                {
                    rel: 'icon',
                    type: 'image/png',
                    href: '/favicon.ico',
                },
                {
                    rel: 'canonical',
                    href: st.seo.url,
                },
            ],
            meta: [
                {
                    name: 'title',
                    content: !isBlank(st.seo.title) ? st.seo.title : 'Propdeal',
                },
                {
                    name: 'description',
                    content: !isBlank(st.seo.description)
                        ? st.seo.description
                        : 'Propdeal',
                },
                {
                    name: 'keywords',
                    content: !isBlank(st.seo.keywords)
                        ? st.seo.keywords
                        : 'Propdeal',
                },
                {
                    property: 'og:type',
                    content: `website`,
                    hid: 'og:type',
                },
                {
                    property: 'og:url',
                    content: `${st.seo.url}`,
                    hid: 'og:url',
                },
                {
                    property: 'og:title',
                    content: !isBlank(st.seo.og_title)
                        ? st.seo.og_title
                        : 'Propdeal',
                    hid: 'og:title',
                },
                {
                    property: 'og:description',
                    content: st.seo.og_description,
                    hid: 'og:description',
                },
                {
                    property: 'og:image',
                    content: st.seo.og_image,
                    hid: 'og:image',
                },
                {
                    property: 'twitter:card',
                    content: `summary_large_image`,
                    hid: 'twitter:card',
                },
                {
                    property: 'twitter:url',
                    content: `${st.seo.url}`,
                    hid: 'twitter:url',
                },
                {
                    property: 'twitter:title',
                    content: !isBlank(st.seo.twitter_title)
                        ? st.seo.twitter_title
                        : 'Propdeal',
                    hid: 'twitter:title',
                },
                {
                    property: 'twitter:description',
                    content: st.seo.twitter_description,
                    hid: 'twitter:description',
                },
                {
                    property: 'twitter:image',
                    content: st.seo.twitter_image,
                    hid: 'twitter:image',
                },
            ],
        }
    },
}
