
// eslint-disable-next-line no-unused-vars
import _ from 'lodash'
import { get } from 'vuex-pathify'
import GridItem from '../../components/content/GridItem'

import { useSearchable } from '../../composables'

export default {
    components: {
        GridItem,
    },
    setup(props, { emit }) {
        const {
            btsDistance,
            distanceOptions,
            projectStatus,
            statusOptions,
            orderByPrice,
            orderOptions,
            currentSearch,
            updateSearchOption,
            currentSearchOption,
        } = useSearchable(emit)

        return {
            btsDistance,
            distanceOptions,
            projectStatus,
            statusOptions,
            orderByPrice,
            orderOptions,
            currentSearch,
            updateSearchOption,
            currentSearchOption,
        }
    },
    async asyncData({ store, params, error }) {
        try {
            const totalResult = await store.dispatch(
                'collection/load',
                params.slug
            )
            return {
                totalResult,
            }
        } catch (err) {
            if (err.response.status === 404) {
                return error({
                    statusCode: 400,
                    message: err.message,
                })
            } else {
                return error({
                    statusCode: 500,
                    message: err.message,
                })
            }
        }
    },
    data() {
        return {
            showMobileFilter: false,
            moreParams: {},
            pagination: {},
        }
    },
    computed: {
        result: get('collection/collection'),
        slug() {
            return this.$route.params.slug
        },
        title() {
            let title = ''

            if (this.totalResult) {
                title = this.totalResult.title
            }

            return title
        },
        distanceFilter: {
            get() {
                return this.btsDistance || ''
            },
            set(val) {
                this.btsDistance = val
            },
        },
    },
    watch: {
        async slug() {
            this.totalResult = await this.$store.dispatch(
                'collection/load',
                this.slug
            )

            // this.updateSearchOption(this.query, this.pagination.total)
        },
    },
    methods: {
        async loadMore() {
            const result = await this.$store.dispatch(
                'collection/load',
                this.slug
            )

            this.totalResult = [...this.totalResult, ...result]
        },
    },
    head() {
        return {
            title: this.title,
        }
    },
}
