
/* eslint-disable no-unused-vars */
import _ from 'lodash'

import he from 'he'

import { get } from 'vuex-pathify'
import SwiperGrid from '@/components/content/SwiperGrid.vue'
import GridItem from '../components/content/GridItem'
import MobileFilter from '../components/MobileFilter'
import HeroTop from '../components/content/DealHeroTop'
import HeroBottom from '../components/content/DealHeroBottom'
import Breadcrumb from '../components/content/Breadcrumb.vue'

import { useSearchable } from '../composables'

import {
    checkLocationSearch,
    checkTrainSearch,
    fetchPromotion,
    trainFilterLabel,
    fetchLocation,
    buildSEO,
    getTrainLabel,
} from '../common/utils'
import errorVue from '~/layouts/error.vue'

export default {
    components: {
        GridItem,
        MobileFilter,
        HeroTop,
        HeroBottom,
        Breadcrumb,
        SwiperGrid,
    },
    setup(props, { emit }) {
        const {
            btsDistance,
            propertyTypeSelect,
            distanceOptions,
            projectStatus,
            statusOptions,
            orderByPrice,
            orderOptions,
            currentSearch,
            updateSearchOption,
            currentSearchOption,
            propertyOptions,
        } = useSearchable(emit)

        return {
            btsDistance,
            propertyTypeSelect,
            distanceOptions,
            projectStatus,
            statusOptions,
            orderByPrice,
            orderOptions,
            currentSearch,
            updateSearchOption,
            currentSearchOption,
            propertyOptions,
        }
    },
    async asyncData({ error, store, params, query, route }) {
        let result = {}
        try {
            let meta = route.meta.length > 0 ? route.meta[0] : {}
            let q = _.merge(_.cloneDeep(query), _.omit(meta, 'content', 'seo'))

            const page = await store.dispatch(
                'pages/get',
                encodeURI(params.slug)
            )

            result.page = page
            result.titleText = ''

            if (page.type === 'page') {
                const contentObj = {}
                if (page.meta_tags && page.meta_tags.seo) {
                    const seo = page.meta_tags.seo
                    contentObj.name = seo.title
                    contentObj.meta_description = seo.description
                    contentObj.meta_keyword = seo.keyword
                    result.titleText = page.title + ' | Propdeal'
                }

                await buildSEO(store, route, contentObj)
            } else if (page.type === 'deals') {
                try {
                    q = _.cloneDeep(page.query)
                    q.sort = page.sort
                    meta = _.cloneDeep(page.meta_tags)

                    _.forOwn(q, (value, key) => {
                        const str =
                            typeof value === 'string'
                                ? value.replace(/\+/g, ' ')
                                : value

                        q[key] = str
                    })

                    if (q.has_train === 'true' || q.has_train === true) {
                        q.has_train = true
                    }

                    const isLocationSearch = checkLocationSearch(q)
                    const locObject = await fetchLocation(
                        store,
                        q,
                        encodeURI(q.location)
                    )
                    const isTrainSearch = checkTrainSearch(q)
                    if (q.sort !== '') {
                        //
                    } else if (isTrainSearch) {
                        q.sort = 'distance_from_bts:asc'
                    } else {
                        q.sort = 'price:asc'
                    }
                    await fetchPromotion(store, q, encodeURI(q.location))
                    const totalResult = await store.dispatch('deals/search', q)
                    // const customContent = {}
                    // if (meta.content) {
                    //     customContent.content = meta.content
                    // }
                    const contentObj = _.assign({}, locObject)
                    if (page.meta_tags && page.meta_tags.seo) {
                        const seo = page.meta_tags.seo
                        contentObj.name = seo.title
                        contentObj.meta_description = seo.description
                        contentObj.meta_keyword = seo.keyword
                        result.titleText = page.title + ' | Propdeal'
                    }

                    await buildSEO(store, route, contentObj)

                    result = _.assign(result, {
                        totalResult,
                        isLocationSearch,
                        isTrainSearch,
                        location: locObject,
                        customContent: meta,
                        sort: q.sort,
                    })
                } catch (err) {
                    result = _.assign(result, {
                        totalResult: [],
                        isLocationSearch: false,
                        isTrainSearch: false,
                    })
                }
            }

            return result
        } catch (err) {
            if (err.response && err.response.status === 404) {
                return error({
                    statusCode: 404,
                    message: err.message,
                })
            } else {
                return error({
                    statusCode: 500,
                    message: err.message,
                })
            }
        }
    },
    data() {
        return {
            showMobileFilter: false,
            showTrainFilter: false,
            moreParams: {},
            promotions: [],
            breadcrumb: {},
        }
    },
    computed: {
        slug() {
            return this.$route.params.slug
        },
        isPage() {
            return this.page && this.page.type === 'page'
        },
        isDealSearch() {
            return this.page && this.page.type === 'deals'
        },
        isTrain() {
            return this.page && this.page.type === 'train'
        },
        result: get('deals/result'),
        train: get('deals/train'),
        pagination: get('deals/pagination'),
        collections: get('collection/collection'),
        metadata() {
            if (this.customContent) {
                const content = this.customContent
                return {
                    header: {
                        title: content.header ? content.header.title : null,
                        description: content.header
                            ? content.header.description
                            : null,
                    },
                    body: {
                        title: content.body ? content.body.title : null,
                        subtitle: content.body ? content.body.subtitle : null,
                    },
                    footer: {
                        title: content.footer ? content.footer.title : null,
                        description: content.footer
                            ? content.footer.description
                            : null,
                    },
                }
            } else if (this.location) {
                return {
                    header: {
                        title: this.location.header
                            ? this.location.header.title
                            : null,
                        description: this.location.header
                            ? this.location.header.description
                            : null,
                    },
                    body: {
                        title: this.location.body
                            ? this.location.body.title
                            : null,
                        subtitle: this.location.body
                            ? this.location.body.subtitle
                            : null,
                    },
                    footer: {
                        title: this.location.footer
                            ? this.location.footer.title
                            : null,
                        description: this.location.footer
                            ? this.location.footer.description
                            : null,
                    },
                }
            } else {
                return {
                    header: {},
                    body: {},
                    footer: {},
                }
            }
        },
        trainFilterLabel() {
            return trainFilterLabel(this.query)
        },
        // isLocationSearch() {
        //     return this.currentSearch.location || false
        // },
        isCategorySearch() {
            return this.propertyFilter !== ''
        },
        displayOption() {
            let trainFilterType = ''
            if (this.isTrainSearch) {
                if (this.currentSearch.station) {
                    trainFilterType = 'station'
                } else if (this.currentSearch.line) {
                    trainFilterType = 'line'
                } else if (this.currentSearch.train) {
                    trainFilterType = 'train'
                } else {
                    trainFilterType = 'has_train'
                }
            }
            return {
                location: this.isLocationSearch,
                train: this.isTrainSearch,
                trainFilterType,
            }
        },
        filterOrderOption() {
            if (this.isLocationSearch) {
                return this.orderOptions.filter((i) =>
                    i.value.startsWith('price')
                )
            } else {
                return this.orderOptions
            }
        },
        query() {
            // return _.merge(
            //     this.$route.query,
            //     _.omit(this.$route.meta, 'content', 'seo')
            // )
            return _.assign(this.page.query, this.page.sort)
        },
        title() {
            if (this.isDealSearch) {
                let title = 'โครงการ'

                if (this.breadcrumb.category) {
                    title += this.breadcrumb.category
                }

                title += 'ยอดนิยม'

                title += (this.breadcrumb.location || 'ทุกทำเล') + '|'

                if (this.breadcrumb.station) {
                    title += title +=
                        'รถไฟฟ้า สถานี ' + this.breadcrumb.station + '|'
                }

                title +=
                    (this.breadcrumb.developer || 'ทุกเจ้าของโครงการ') + '|'

                if (this.breadcrumb.brand) {
                    title += (this.breadcrumb.brand || 'ทุกแบรนด์') + '|'
                }

                if (this.breadcrumb.train) {
                    title += 'ติดรถไฟฟ้า ' + this.breadcrumb.train + '|'
                }

                if (this.breadcrumb.price) {
                    title += this.breadcrumb.price + ' ล้านบาท'
                } else {
                    title += 'ทุกราคา'
                }

                return title
            } else {
                return this.titleText
            }
        },
        propertyFilter: {
            get() {
                return this.propertyTypeSelect || ''
            },
            set(val) {
                this.propertyTypeSelect = val
            },
        },
        distanceFilter: {
            get() {
                return this.btsDistance || ''
            },
            set(val) {
                this.btsDistance = val
            },
        },
        trainName() {
            return this.train ? getTrainLabel(this.train.type) : ''
        },
        sortText() {
            if (this.sort === 'price:asc') {
                return 'เรียงราคาจากน้อยไปมาก'
            } else if (this.sort === 'price:desc') {
                return 'เรียงราคาจากมากไปน้อย'
            } else if (this.sort === 'distance_from_bts:asc') {
                return 'เรียงระยะห่างจากรถไฟฟ้าน้อยไปมาก'
            } else if (this.sort === 'distance_from_bts:desc') {
                return 'เรียงระยะห่างจากรถไฟฟ้ามากไปน้อย'
            } else {
                return 'เรียงราคาจากน้อยไปมาก'
            }
        },
        html() {
            return he.decode(this.page.html)
        },
    },
    watch: {
        async '$route.query'() {
            if (this.isDealSearch) {
                this.propertyFilter = this.query.category
                this.isLocationSearch = checkLocationSearch(this.query)
                this.isTrainSearch = checkTrainSearch(this.query)
                if (this.query.sort !== '') {
                    //
                } else if (this.isTrainSearch) {
                    this.orderByPrice = 'distance_from_bts:asc'
                } else {
                    this.orderByPrice = 'price:asc'
                }
                this.sort = this.orderByPrice
                this.totalResult = await this.$store.dispatch(
                    'deals/search',
                    this.query
                )

                await fetchPromotion(
                    this.$store,
                    this.query,
                    this.isLocationSearch
                )

                this.location = await fetchLocation(
                    this.$store,
                    this.query,
                    this.isLocationSearch
                )

                this.breadcrumb = _.cloneDeep(this.currentSearch)
                await buildSEO(this.$store, this.$route, this.location)

                this.mapPromotion()
                this.updateSearchOption({})

                // const query = _.cloneDeep(this.query)

                // query.station = ''
                // query.location = ''

                // this.updateSearchOption(query, this.pagination.total)
            }
        },
        showMobileFilter(val) {
            this.$bus.$emit('on-popup-open', val)
        },
    },
    mounted() {
        // if (this.query.catetory) {
        //     this.propertyFilter = this.query.catetory
        //     this.applyFilter(null)
        // }
        this.mapPromotion()
        this.breadcrumb = _.cloneDeep(this.currentSearch)
        this.updateSearchOption({})
    },
    methods: {
        mapPromotion() {
            if (this.currentSearch.location) {
                const slug = this.currentSearch.location // encodeURI(this.currentSearch.location)
                const collection =
                    this.collections[slug] || this.collections.promotions

                this.promotions = {
                    title: collection ? collection.title : '',
                    subtitle: collection ? collection.subtitle : '',
                    items: _.map(collection ? collection.ads : [], (ads) => ({
                        name: ads.name,
                        image: ads.feature_image,
                        url: ads.url,
                    })),
                }
            } else {
                this.promotions = {
                    title: 'ทำเลยอดนิยมแนะนำ',
                    subtitle: ' ',
                    items: [],
                }
            }
        },
        closeTrainFilter() {
            this.showTrainFilter = false
        },
        async applyFilter(filters) {
            const params = _.cloneDeep(this.query)
            // mobile
            if (filters) {
                if (filters.btsDistance.value) {
                    this.btsDistance = filters.btsDistance.value
                }
                if (filters.projectStatus.value) {
                    this.projectStatus = filters.projectStatus.value
                }
                if (filters.orderByPrice.value) {
                    this.orderByPrice = filters.orderByPrice.value
                }
                if (filters.propertyTypeSelect.value) {
                    this.propertyTypeSelect = filters.propertyTypeSelect.value
                }
            }

            if (this.btsDistance) {
                params.distance_from_bts = this.btsDistance
            }

            if (this.projectStatus) {
                params.project_status = this.projectStatus
            }

            if (this.orderByPrice) {
                params.sort = this.orderByPrice
            }

            if (this.propertyTypeSelect) {
                params.category = this.propertyTypeSelect
            }

            this.moreParams = params
            this.totalResult = await this.$store.dispatch(
                'deals/search',
                _.omit(params, 'page')
            )
        },
        async loadMore() {
            const result = await this.$store.dispatch(
                'deals/search',
                _.assign(
                    this.moreParams,
                    _.assign(this.query, {
                        page: this.pagination.page + 1,
                    })
                )
            )

            this.totalResult = [...this.totalResult, ...result]
        },
        openLink(i, item) {
            if (typeof item !== 'undefined') {
                window.location.href = item.url
            }
        },
    },
    head() {
        return {
            title: this.title,
        }
    },
}
