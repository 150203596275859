
import SwiperGrid from './SwiperGrid.vue'
export default {
    components: {
        SwiperGrid,
    },
    props: {
        showModal: {
            type: Boolean,
            default: false,
        },
        plan: {
            type: Object,
            default: () => ({}),
        },
    },
    computed: {
        galleries() {
            return this.plan.galleries.map((item) => ({
                image: item.url,
            }))
        },
    },
}
