
/* eslint-disable no-unused-vars */
import _ from 'lodash'
import { get } from 'vuex-pathify'
import GridItem from '../../components/content/GridItem'

export default {
    components: {
        GridItem,
    },
    async asyncData({ store, params }) {
        const totalResult = await store.dispatch('brands/getFeatured', {
            sort: 'featured:desc',
            limit: 16,
        })
        return {
            totalResult,
        }
    },
    data: () => ({}),
    computed: {
        pagination: get('brands/pagination'),
    },
    methods: {
        async loadMore() {
            const result = await this.$store.dispatch('brands/getFeatured', {
                sort: 'featured:desc',
                limit: 8,
                page: this.pagination.page + 1,
            })

            this.totalResult = [...this.totalResult, ...result]
        },
    },
    head() {
        return {
            title: 'แบรนด์คอนโดชั้นนำ | Prodeal',
        }
    },
}
