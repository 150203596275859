
import he from 'he'
import truncate from 'truncate-html'

export default {
    props: {
        trainFilterLabel: {
            type: String,
            default: '',
        },
        metadata: {
            type: Object,
            default: () => {},
        },
        breadcrumb: {
            type: Object,
            default: () => {},
        },
    },
    methods: {
        html(text) {
            let result = ''
            if (text) {
                result = he.decode(text.replace(/\n/g, '<br>'))
                result = truncate(result, 500, {
                    stripTags: false,
                    ellipsis: '',
                })
            }
            return result
        },
    },
}
