
import _ from 'lodash'
import { directive } from 'v-aspect-ratio'
export default {
    directives: {
        'aspect-ratio': directive,
    },
    props: {
        cleanBackground: {
            type: Boolean,
            default: false,
        },
        backgroundBlur: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default() {
                return {}
            },
        },
        showDetail: {
            type: Boolean,
            default: false,
        },
        showOverlay: {
            type: Boolean,
            default: false,
        },
        showTrain: {
            type: Boolean,
            default: false,
        },
        category: {
            type: String,
            default: '',
        },
        autoHeight: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            nearestStation: null,
            operator: null,
        }
    },
    computed: {
        nearestStationName() {
            return this.nearestStation.station.name
        },
        nearestDistance() {
            const distance = this.nearestStation.distance
            return typeof distance === 'number' ? distance / 1000.0 : ''
        },
        typeImage() {
            let url = '/images/bottom-banner.png'
            if (this.item && this.item.type === 'stats') {
                url = this.item.image ? this.item.image : url
                if (
                    this.item.medias &&
                    typeof this.item.medias[this.category] !== 'undefined'
                ) {
                    url = this.item.medias[this.category]
                }
            }
            return url
        },
    },
    watch: {
        item: {
            immediate: true,
            handler() {
                this.nearestStation = this.getNearestStation(this.item)
                this.getNearestOperator(this.item)
            },
            deep: true,
        },
    },
    methods: {
        getNearestStation(item) {
            // if (item._id === '602957f993c0540026576538') window.item = item
            const station = {
                station: { name: '' },
                distance: '',
            }
            if (item.has_train) {
                if (item.trains_nearby.length > 0) {
                    const s = _.orderBy(item.trains_nearby, ['distance'])

                    if (s[0]) {
                        return s[0]
                    }
                }
            }
            return station
        },
        getNearestOperator(item) {
            if (item.has_train) {
                if (
                    item.train_operator_nearby &&
                    item.train_operator_nearby.length > 0
                ) {
                    this.operator = item.train_operator_nearby[0].operator
                }
            } else {
                this.operator = ''
            }
        },
    },
}
