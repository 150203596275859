
import { propertyOptions } from '../../composables/options'
import { useSearchable, useDevelopers } from '../../composables'

import TransportFilter from '../../components/content/TransportFilter'
import LocationFilter from '../../components/content/LocationFilter'
import DeveloperFilter from '../../components/content/DeveloperFilter'
import BrandFilter from '../../components/content/BrandFilter'

import PriceBox from '../../components/content/PriceBox'

import MobileSearch from './MobileSearch.vue'
import MobileMenu from './MobileMenu.vue'

export default {
    components: {
        TransportFilter,
        LocationFilter,
        MobileSearch,
        MobileMenu,
        DeveloperFilter,
        BrandFilter,
        PriceBox,
    },
    setup(props, { emit }) {
        const {
            currentSearch,
            sectionConfigs,
            currentSearchOption,
            renderSuggestion,
            getSuggestionValue,
            updateSearchOption,
            querySuggestion,
            querySuggestionResult,
            doSearch,
        } = useSearchable(emit)

        const { queryDeveloper, queryDeveloperResult } = useDevelopers(emit)

        const mainSuggestion = (suggestion) => {
            if (suggestion.name === 'deals') {
                updateSearchOption(
                    null,
                    0,
                    'deal',
                    suggestion.item.title,
                    suggestion.item.url
                )
            } else if (suggestion.name === 'locations') {
                updateSearchOption(
                    null,
                    0,
                    'location',
                    suggestion.item.title,
                    null,
                    true
                )
            } else if (suggestion.name === 'stations') {
                if (
                    ['BTS', 'MRT', 'ARL', 'bts', 'mrt', 'arl'].includes(
                        suggestion.item.title
                    )
                ) {
                    updateSearchOption(
                        null,
                        0,
                        'train',
                        suggestion.item.title,
                        null,
                        true
                    )
                } else {
                    updateSearchOption(
                        null,
                        0,
                        'station',
                        suggestion.item.title,
                        null,
                        true
                    )
                }
            }
        }

        sectionConfigs.deals.onSelected = mainSuggestion
        sectionConfigs.locations.onSelected = mainSuggestion
        sectionConfigs.stations.onSelected = mainSuggestion

        return {
            currentSearch,
            sectionConfigs,
            currentSearchOption,
            queryDeveloper,
            renderSuggestion,
            getSuggestionValue,
            queryDeveloperResult,
            updateSearchOption,
            querySuggestion,
            querySuggestionResult,
            mainSuggestion,
            doSearch,
        }
    },
    data() {
        return {
            propertyOptions,
            showMobileSearch: false,
            showMobileMenu: false,
            openTranspontionMenu: false,
            openLocationMenu: false,
            openDeveloperMenu: false,
            openBrandMenu: false,
            mobileIndex: 0,
            scrollpy: 0,
            showFixedSearch: false,
        }
    },
    computed: {
        category: {
            get() {
                return this.currentSearchOption.category || ''
            },
            set(val) {
                this.updateSearchOption(null, 0, 'category', val)
            },
        },
    },
    watch: {
        showMobileSearch(val) {
            this.$bus.$emit('on-popup-open', val)
        },
        showMobileMenu(val) {
            this.$bus.$emit('on-popup-open', val)
        },
    },
    mounted() {
        window.addEventListener('scroll', this.handleScroll)
        const vm = this
        this.$bus.$on('open-mobile-search', (index) => {
            vm.showMobileSearch = true
            if (typeof index !== 'undefined') vm.mobileIndex = index
        })
    },
    destroyed() {
        window.removeEventListener('scroll', this.handleScroll)
        this.$bus.$off('open-mobile-search')
    },
    methods: {
        toggleTrainMenu(flag) {
            this.openTranspontionMenu = flag
            if (flag) {
                this.openLocationMenu = false
                this.openDeveloperMenu = false
                this.openBrandMenu = false
            }
        },
        toggleLocationMenu(flag) {
            this.openLocationMenu = flag
            if (flag) {
                this.openTranspontionMenu = false
                this.openDeveloperMenu = false
                this.openBrandMenu = false
            }
        },
        toggleDeveloperMenu(flag) {
            this.openDeveloperMenu = flag
            if (flag) {
                this.openTranspontionMenu = false
                this.openLocationMenu = false
                this.openBrandMenu = false
            }
        },
        toggleBrandMenu(flag) {
            this.openBrandMenu = flag
            if (flag) {
                this.openTranspontionMenu = false
                this.openLocationMenu = false
                this.openDeveloperMenu = false
            }
        },
        onSearching() {
            this.$router.push({ path: '/deals' })
            this.showMobileSearch = false
        },
        onMobileSearchClose() {
            this.showMobileSearch = false
        },
        handleScroll() {
            this.scrollpy = window.scrollY
            if (this.scrollpy > 300 && window.innerWidth > 576) {
                this.showFixedSearch = true
                this.$bus.$emit('show-fixed-search', true)
            } else {
                this.showFixedSearch = false
                this.$bus.$emit('show-fixed-search', false)
            }
        },
    },
}
