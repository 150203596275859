
// eslint-disable-next-line no-unused-vars
import Vue from 'vue'
import _ from 'lodash'

import { get } from 'vuex-pathify'

import { getActiveTrainIndex } from '@/common/utils'

export default {
    props: {
        filable: {
            type: Boolean,
            default: false,
        },
        isInputMode: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            trains: [],
        }
    },
    computed: {
        listTrain: get('trains/lines'),
        groupByOperator() {
            const operators = []
            _.forEach(this.trains, (line, i) => {
                const exist = _.find(operators, (o) => o.type === line.type)
                line.idx = i
                if (exist) {
                    exist.lines.push(line)
                } else {
                    operators.push({
                        type: line.type,
                        lines: [line],
                    })
                }
            })

            return operators
        },
    },
    watch: {
        listTrain() {
            this.trains = _.cloneDeep(this.listTrain)
        },
    },
    mounted() {
        const vm = this
        this.trains = _.cloneDeep(this.listTrain)
        _.forEach(this.groupByOperator, (operator) => {
            vm.explanedTrain(operator.lines[0])
        })
        this.$bus.$on('search-all-train', () => {
            vm.searchAllTrain()
        })
        this.$bus.$on('clear-all-train', () => {
            vm.clearAllTrainSeatch()
        })
    },
    destroyed() {
        this.$bus.$off('search-all-train')
        this.$bus.$off('clear-all-train')
    },
    methods: {
        activeTab(idx) {
            return idx === getActiveTrainIndex(this.$route.query)
        },
        explanedTrain(train) {
            // const train = this.trains[i]
            train.state = !train.state
            Vue.set(this.trains, train.idx, train)
        },
        searchByStation(code) {
            this.acceptAction({ station: code })
        },
        searchByType(code) {
            this.acceptAction({ train: code })
        },
        searchAllTrain() {
            this.acceptAction({ has_train: true })
        },
        clearAllTrainSeatch() {
            this.acceptAction({ clear: true })
        },
        searchByLine(line) {
            this.acceptAction({ line })
        },
        acceptAction(query) {
            const result = query
            if (this.isInputMode) {
                if (query.clear) {
                    result.text = ''
                    this.$emit('accept', { clear: true })
                    return
                }
                if (query.has_train) {
                    result.text = 'ทั้งหมด'
                } else if (query.line) {
                    result.text = query.line
                } else if (query.station) {
                    result.text = query.station
                } else if (query.train) {
                    result.text = query.train + ' ทุกสถานี'
                }
                this.$emit('accept', result)
            } else {
                this.$router.push({ path: '/deals', query })
                // this.showMobileMenu = false
                this.$emit('close')
            }
        },
    },
}
