
import _ from 'lodash'
import { get } from 'vuex-pathify'
import { directive } from 'v-aspect-ratio'
import { defineComponent } from '@vue/composition-api'

function isBlank(str) {
    return !str || /^\s*$/.test(str)
}

export default defineComponent({
    directives: {
        'aspect-ratio': directive,
    },
    setup() {},
    async fetch(context) {
        const { store, params, route, redirect } = context
        try {
            await store.dispatch('deals/getDeal', encodeURI(params.slug))
        } catch (err) {
            return redirect('/')
        }

        // build seo
        const setting = store.state.map
        const article = store.state.deals.deal
        const seo = _.cloneDeep(store.state.seo)

        seo.url = `${setting.url}${route.fullPath}`
        seo.og_url = seo.url
        seo.twitter_url = seo.url

        seo.title = article.name + ' | Propdeal'

        seo.description = isBlank(article.meta_description)
            ? setting.description
            : article.meta_description
        seo.og_description = seo.description
        seo.twitter_description = seo.description
        seo.keywords = isBlank(article.meta_keyword)
            ? setting.meta_keyword
            : article.meta_keyword + ' ' + (setting.meta_keyword || '')
        seo.og_title =
            (isBlank(article.name) ? seo.title : article.name) + ' | Propdeal'
        seo.twitter_title = seo.og_title

        seo.og_image = isBlank(article.feature_image)
            ? setting.og_image
            : article.feature_image
        seo.twitter_image = isBlank(article.feature_image)
            ? setting.twitter_image
            : article.feature_image

        await store.commit('SET_SEO', seo)
    },
    computed: {
        slug() {
            return this.$route.params.slug
        },
        deal: get('deals/deal'),
    },
})
