
import TrainList from '../TrainList'

export default {
    props: {
        active: {
            type: Boolean,
            default: false,
        },
        isInputMode: {
            type: Boolean,
            default: false,
        },
        outsideEvent: {
            type: Boolean,
            default: false,
        },
    },
    component: {
        TrainList,
    },
    data() {
        return {}
    },
    methods: {
        outsideClick() {
            if (this.outsideEvent) {
                this.close()
            }
        },
        accept(event) {
            this.$emit('accept', event)
        },
        close() {
            this.$emit('close')
        },
    },
}
