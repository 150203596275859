
import { directive } from 'v-aspect-ratio'
export default {
    directives: {
        'aspect-ratio': directive,
    },
    props: {
        cleanBackground: {
            type: Boolean,
            default: false,
        },
        backgroundBlur: {
            type: Boolean,
            default: false,
        },
        item: {
            type: Object,
            default() {
                return {}
            },
        },
        showDetail: {
            type: Boolean,
            default: false,
        },
        showOverlay: {
            type: Boolean,
            default: false,
        },
    },
}
