
export default {
    props: {
        value: {
            type: Object,
            default() {
                return {
                    min: 0,
                    max: 1,
                }
            },
        },
        min: {
            type: Number,
            default: 0,
        },
        max: {
            type: Number,
            default: 80,
        },
    },
    data() {
        return {
            minAngle: this.min,
            maxAngle: this.max,
        }
    },
    computed: {
        sliderMin: {
            get() {
                return this.value.min
            },
            set(val) {
                val = parseInt(val)
                if (val > this.maxAngle) {
                    this.maxAngle = val
                }
                this.minAngle = val
                this.updateData()
            },
        },
        sliderMax: {
            get() {
                return this.value.max
            },
            set(val) {
                val = parseInt(val)
                if (val < this.minAngle) {
                    this.minAngle = val
                }
                this.maxAngle = val
                this.updateData()
            },
        },
    },
    methods: {
        updateData() {
            this.$emit('input', {
                min: this.minAngle,
                max: this.maxAngle,
            })
        },
    },
}
