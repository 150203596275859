
import _ from 'lodash'
import SwiperItem from './SwiperItem2'

export default {
    components: {
        SwiperItem,
    },
    props: {
        cleanBackground: {
            type: Boolean,
            default: false,
        },
        backgroundBlur: {
            type: Boolean,
            default: false,
        },
        loop: {
            type: Boolean,
            default: false,
        },
        enableControl: {
            type: Boolean,
            default: false,
        },
        enableIndicator: {
            type: Boolean,
            default: false,
        },
        paginationName: {
            type: String,
            default: 'swiper-pagination-name',
        },
        slidesPerView: {
            type: Number,
            default: 2,
        },
        items: {
            type: Array,
            default() {
                return []
            },
        },
        swiperStyle: {
            type: String,
            default: 'swiper',
        },
        showDetail: {
            type: Boolean,
            default: false,
        },
        showOverlay: {
            type: Boolean,
            default: false,
        },
        breakpoints: {
            type: Object,
            default() {
                return {
                    1600: {
                        slidesPerView: 3,
                        spaceBetween: 20,
                    },
                    1024: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    576: {
                        slidesPerView: 1,
                        spaceBetween: 10,
                    },
                }
            },
        },
    },
    data() {
        return {}
    },
    computed: {
        swiperOptions() {
            const options = {
                slidesPerView: this.slidesPerView,
                centeredSlides: false,
                spaceBetween: 20,
                grabCursor: false,
                breakpoints: this.breakpoints,
                pagination: {
                    el: '.' + this.paginationName,
                    clickable: true,
                },
                loop: this.loop,
            }
            if (this.enableControl && this.loop) {
                _.assign(options, {
                    autoplay: {
                        delay: 5000,
                        disableOnInteraction: false,
                    },
                })
            }
            if (this.enableIndicator || this.enableControl) {
                _.assign(options, {
                    navigation: {
                        nextEl:
                            '.' + this.paginationName + '.swiper-button-next',
                        prevEl:
                            '.' + this.paginationName + '.swiper-button-prev',
                    },
                })
            }
            return options
        },
    },
}
