
import { useLocations } from '../../composables'
import SwiperGrid from './SwiperGrid.vue'
export default {
    components: {
        SwiperGrid,
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { locations, searchByLocation } = useLocations(emit)

        return {
            locations,
            searchByLocation,
        }
    },
    data() {
        return {}
    },
    computed: {},
    methods: {
        close() {
            this.$emit('close')
        },
    },
}
