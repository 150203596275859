
import { directive } from 'v-aspect-ratio'
import { call } from 'vuex-pathify'
import VueTurnstile from './vue-turnstile'

function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(email).toLowerCase())
}

function validatePhone(phone) {
    const regex = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/
    return regex.test(String(phone))
}

export default {
    components: { VueTurnstile },
    directives: {
        'aspect-ratio': directive,
    },
    props: {
        deal: {
            type: Object,
            default() {
                return {
                    name: '',
                }
            },
        },
    },
    data() {
        return {
            loading: false,
            showMobileForm: false,
            name: '',
            phone: '',
            email: '',
            token: '',
        }
    },
    watch: {
        showMobileForm(val) {
            this.$bus.$emit('on-popup-open', val)
        },
    },
    methods: {
        sendLeadForm: call('deals/sendLeadForm'),
        clearForm() {
            this.name = this.phone = this.email = ''
        },
        async sendLead() {
            if (this.loading) {
                return
            }
            let url = this.$route.fullPath

            if (process.client) {
                url = window.location.href
            }

            if (
                this.name === '' ||
                this.phone === '' ||
                !this.token ||
                this.token === '' ||
                !validateEmail(this.email) ||
                !validatePhone(this.phone)
            ) {
                let errrText = 'กรุณากรอกข้อมูลให้ถูกต้อง'
                if (!this.token || this.token === '') {
                    errrText += '<br> - กรุณายืนยัน Captcha'
                }
                if (this.name === '') {
                    errrText += '<br> - กรุณากรอกชื่อ'
                }
                if (this.phone === '') {
                    errrText += '<br> - กรุณากรอกเบอร์โทรศัพท์'
                }
                if (this.email === '') {
                    errrText += '<br> - กรุณากรอกอีเมลล์'
                }
                if (this.phone !== '' && !validatePhone(this.phone)) {
                    errrText +=
                        '<br> - กรุณากรอกเบอร์โทรศัพท์ให้ถูกต้อง 10 หลัก'
                }
                if (this.email !== '' && !validatePhone(this.email)) {
                    errrText += '<br> - อีเมลล์ไม่ได้กรอกหรือผิดรูปแบบ'
                }
                this.$vs.notification({
                    position: 'top-center',
                    color: 'danger',
                    title: 'ลงทะเบียนไม่สำเร็จ',
                    text: errrText,
                })
            } else {
                this.loading = true
                try {
                    await this.sendLeadForm({
                        deal: this.deal.name,
                        deal_en: this.deal.name_en,
                        name: this.name,
                        phone: this.phone,
                        email: this.email,
                        token: this.token,
                        url,
                    })
                    // this.$vs.notification({
                    //     position: 'top-center',
                    //     color: 'primary',
                    //     title: 'ลงทะเบียนเรียบร้อย',
                    //     text: `ขอบคุณที่ให้ความสนใจ เจ้าหน้าที่จะติดต่อหาท่าน ภายใน  1 ชม.`,
                    // })
                    this.showMobileForm = false
                    await this.$router.push({
                        path: `/deals/${this.deal.slug}/thankyou`,
                    })
                } catch (error) {
                    // eslint-disable-next-line no-console
                    console.err(error)
                }
                this.loading = false
            }
        },
    },
}
