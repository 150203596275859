
export default {
    data() {
        return {
            links: [
                {
                    title: 'รถไฟฟ้า',
                    url: '/deals?has_train=true',
                },
                {
                    title: 'ทำเล',
                    url: '/areas',
                },
                {
                    title: 'เจ้าของโครงการ',
                    url: '/developers',
                },
                {
                    title: 'แบรนด์',
                    url: '/brands',
                },
                {
                    title: 'ดีลร้อนแรง',
                    url: '/features/hot',
                },
                // {
                //     title: 'เกี่ยวกับเรา',
                //     url: '/pages/about',
                // },
                // {
                //     title: 'ร่วมงาน',
                //     url: '#',
                // },
                {
                    title: 'นโยบายส่วนบุคคล',
                    url: '/privacy',
                },
                // {
                //     title: 'หางาน',
                //     url: '#',
                // },
                // {
                //     title: 'ติดต่อเรา',
                //     url: '/pages/contact',
                // },
            ],
        }
    },
}
