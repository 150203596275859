
import TransportFilter from './TransportFilter'

export default {
    components: { TransportFilter },
    props: {
        noBottomShift: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            showPopup: false,
            inputText: '',
        }
    },
    mounted() {
        const vm = this
        this.$bus.$on('show-fixed-search', (flag) => {
            if (flag) {
                vm.showPopup = false
            }
        })
        this.$bus.$on('clear-searh-text', (flag) => {
            this.inputText = ''
        })
    },
    destroyed() {
        this.$bus.$off('show-fixed-search')
        this.$bus.$off('clear-searh-text')
    },
    methods: {
        togglePopup() {
            this.showPopup = !this.showPopup
        },
        close() {
            this.showPopup = false
        },
        accept(query) {
            this.inputText = query.text
            this.$emit('accept', query)
            this.close()
        },
    },
}
