
import _ from 'lodash'
import he from 'he'
import truncate from 'truncate-html'

import { directive } from 'v-aspect-ratio'

export default {
    directives: {
        'aspect-ratio': directive,
    },
    props: {
        type: {
            type: String,
            default: 'deal',
        },
        options: {
            type: Object,
            default() {
                return {}
            },
        },
        item: {
            type: Object,
            default() {
                return {}
            },
        },
    },
    data() {
        return {
            he,
            nearestStation: null,
            operator: null,
        }
    },
    computed: {
        nearestStationName() {
            return this.nearestStation.station.name
        },
        nearestDistance() {
            const distance = this.nearestStation.distance
            return typeof distance === 'number' ? distance / 1000.0 : ''
        },
    },
    watch: {
        item: {
            immediate: true,
            handler() {
                this.nearestStation = this.getNearestStation(this.item)
                this.getNearestOperator(this.item)
            },
            deep: true,
        },
    },
    methods: {
        getNearestStation(item) {
            const station = {
                station: { name: '' },
                distance: '',
            }
            if (this.options.trainFilterType === 'station') {
                if (item.trains_nearby.length > 0)
                    return item.trains_nearby[0]
                        ? item.trains_nearby[0]
                        : station
                else if (item.trains_nearby) {
                    return item.trains_nearby ? item.trains_nearby : station
                }
            } else if (this.options.trainFilterType === 'line') {
                if (item.trains_nearby.length > 0) {
                    const s = item.trains_nearby.find(
                        (l) => l.station._id === item.train_lines_nearby.station
                    )
                    if (s) {
                        return s
                    }
                }
            } else if (this.options.trainFilterType === 'train') {
                if (item.trains_nearby.length > 0) {
                    const s = item.trains_nearby.find(
                        (l) =>
                            l.station._id === item.train_operator_nearby.station
                    )
                    if (s) {
                        return s
                    }
                }
            } else if (this.options.trainFilterType === 'has_train') {
                if (item.trains_nearby.length > 0) {
                    const s = _.orderBy(item.trains_nearby, ['distance'])
                    if (s[0]) {
                        return s[0]
                    }
                }
            }
            return station
        },
        getNearestOperator(item) {
            if (item.has_train) {
                if (item.train_lines_nearby.length > 0) {
                    _.forEach(item.train_lines_nearby, (train) => {
                        if (train.station === this.nearestStation.station._id) {
                            this.operator = train.train_line.type
                        }
                    })
                }
            } else {
                this.operator = ''
            }
        },
        subtitle(text) {
            let result = ''
            if (text) {
                result = truncate(text, 150, {
                    stripTags: false,
                    ellipsis: '',
                })
            }
            return result
        },
        html(text) {
            return text ? he.decode(text) : ''
        },
    },
}
