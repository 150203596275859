
import { useSearchable } from '../composables'

export default {
    props: {
        openPopup: {
            type: Boolean,
            default: true,
        },
    },
    setup(props, { emit }) {
        const {
            btsDistance,
            distanceOptions,
            projectStatus,
            statusOptions,
            orderByPrice,
            orderOptions,
            clearSearchFilter,
            applyFilter,
            propertyOptions,
            propertyTypeSelect,
        } = useSearchable(emit)

        return {
            btsDistance,
            distanceOptions,
            projectStatus,
            statusOptions,
            orderByPrice,
            orderOptions,
            clearSearchFilter,
            applyFilter,
            propertyOptions,
            propertyTypeSelect,
        }
    },
    data() {
        return {}
    },
    watch: {
        openPopup(val) {},
    },
}
