
/* eslint-disable no-unused-vars */
import _ from 'lodash'
import { get } from 'vuex-pathify'
import GridItem from '../../components/content/GridItem'

export default {
    components: {
        GridItem,
    },
    async asyncData({ store, params }) {
        const features = await store.dispatch('locations/getFeatured')
        const totalResult = await store.dispatch('locations/list', {
            sort: 'featured:desc',
            limit: 50,
        })
        return {
            totalResult,
            features,
        }
    },
    data: () => ({}),
    computed: {
        result: get('locations/locations'),
        pagination: get('locations/pagination'),
    },
    methods: {
        async loadMore() {
            const result = await this.$store.dispatch('locations/list', {
                sort: 'featured:desc',
                limit: 50,
                page: this.pagination.page + 1,
            })

            this.totalResult = _.concat(this.totalResult, result)
        },
    },
    head() {
        return {
            title: 'รวมอสังหาริมทรัพย์ตามจังหวัด และย่านที่น่าสนใจ | Prodeal',
        }
    },
}
