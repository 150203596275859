
export default {
    props: {
        getSuggestionValue: {
            type: Function,
            default: () => {},
        },
        renderSuggestion: {
            type: Function,
            default: () => {},
        },
        icon: {
            type: String,
            default: 'fa-search',
        },
        value: {
            type: String,
            default: '',
        },
        prefix: {
            type: String,
            default: 'auto',
        },
        placeholder: {
            type: String,
            default: '',
        },
        suggestions: {
            type: Array,
            default: () => [],
        },
        sectionConfigs: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            search: '',
        }
    },
    computed: {
        inputProps() {
            return {
                id: `${this.prefix}_suggestion`,
                class: 'autosuggest__input  vs-input',
                placeholder: this.placeholder,
            }
        },
    },
    methods: {
        onSelected($event) {
            this.$emit('selected', $event)
        },
        resetInputValue() {
            const suggestionObj = this.$refs[`${this.prefix}_search`]

            if (suggestionObj) {
                suggestionObj.searchInputOriginal = ''
                suggestionObj.internalValue = ''
            }
        },
    },
}
