
import { useBrands } from '../../composables'
import SwiperGrid from './SwiperGrid.vue'
export default {
    components: {
        SwiperGrid,
    },
    props: {
        active: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const { brands, searchByBrand } = useBrands(emit)

        return {
            brands,
            searchByBrand,
        }
    },
    data() {
        return {}
    },
    computed: {},
    methods: {
        close() {
            this.$emit('close')
        },
    },
}
