
import he from 'he'
import truncate from 'truncate-html'

const defaultTitle = 'ค้นหาโครงการที่ตรงใจคุณ'
const defaultDesc = `ค้นหาอสังหาริมทรัพย์ที่คุณต้องการได้เลย! 
                    จากโครงการคอนโด ทาวน์โฮม บ้านเดี่ยว
                    กว่า 1,000 โครงการใน Propdeal`

export default {
    props: {
        title: {
            type: String,
            default: defaultTitle,
        },
        description: {
            type: String,
            default: defaultDesc,
        },
    },
    data() {
        return {
            defaultTitle,
            defaultDesc,
        }
    },
    methods: {
        scrollToTop() {
            window.scrollTo(0, 0)
        },
        html(text) {
            let result = ''
            if (text) {
                result = he.decode(text.replace(/\n/g, '<br>'))
                result = truncate(result, 500, {
                    stripTags: false,
                    ellipsis: '',
                })
            }
            return result
        },
    },
}
