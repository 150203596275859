
import _ from 'lodash'
import { checkLocationSearch, checkTrainSearch } from '@/common/utils'
import { propertyOptions } from '../../composables/options'
import { useSearchable, useDevelopers, useLocations } from '../../composables'

import RangeSlider from '../RangeSlider'
import MobileTrainBox from '../content/MobileTrainBox.vue'

export default {
    components: {
        RangeSlider,
        MobileTrainBox,
    },
    props: {
        focusIndex: {
            type: Number,
            default: 0,
        },
        openPopup: {
            type: Boolean,
            default: false,
        },
    },
    setup(props, { emit }) {
        const {
            currentSearch,
            sectionConfigs,
            currentSearchOption,
            renderSuggestion,
            getSuggestionValue,
            updateSearchOption,
            querySuggestion,
            querySuggestionResult,
            doSearch,
        } = useSearchable(emit)

        const { queryDeveloper, queryDeveloperResult } = useDevelopers(emit)
        const { queryLocation, queryLocationResult } = useLocations(emit)

        const mainSuggestion = (suggestion) => {
            if (suggestion.name === 'deals') {
                updateSearchOption(
                    null,
                    0,
                    'deal',
                    suggestion.item.title,
                    suggestion.item.url
                )
            } else if (suggestion.name === 'locations') {
                updateSearchOption(
                    null,
                    0,
                    'location',
                    suggestion.item.title,
                    null,
                    true
                )
            } else if (suggestion.name === 'stations') {
                if (
                    ['BTS', 'MRT', 'ARL', 'bts', 'mrt', 'arl'].includes(
                        suggestion.item.title
                    )
                ) {
                    updateSearchOption(null, 0, 'station', '', null, true)
                    updateSearchOption(null, 0, 'line', '', null, true)
                    updateSearchOption(null, 0, 'location', '', null, true)
                    updateSearchOption(
                        null,
                        0,
                        'train',
                        suggestion.item.title,
                        null,
                        true
                    )
                } else {
                    updateSearchOption(null, 0, 'train', '', null, true)
                    updateSearchOption(null, 0, 'line', '', null, true)
                    updateSearchOption(null, 0, 'location', '', null, true)
                    updateSearchOption(
                        null,
                        0,
                        'station',
                        suggestion.item.title,
                        null,
                        true
                    )
                }
            }
        }

        sectionConfigs.deals.onSelected = mainSuggestion
        sectionConfigs.locations.onSelected = mainSuggestion
        sectionConfigs.stations.onSelected = mainSuggestion

        return {
            currentSearch,
            sectionConfigs,
            currentSearchOption,
            queryDeveloper,
            renderSuggestion,
            getSuggestionValue,
            queryDeveloperResult,
            updateSearchOption,
            querySuggestion,
            querySuggestionResult,
            mainSuggestion,
            doSearch,
            queryLocation,
            queryLocationResult,
        }
    },
    data() {
        return {
            keyword: '',
            developer: '',
            filterPrice: {
                min: 0.5,
                max: 50.0,
            },
            propertyOptions,
            locationText: '',
            trainSeach: null,
        }
    },
    computed: {
        category: {
            get() {
                return this.currentSearchOption.category || ' '
            },
            set(val) {
                this.updateSearchOption(null, 0, 'category', val)
            },
        },
    },
    mounted() {
        const index = this.focusIndex

        const vm = this

        if (typeof vm.$refs['input' + index] !== 'undefined') {
            this.$nextTick(() => {
                vm.$refs['input' + index].focus()
            })
        }
    },
    methods: {
        onBlur() {},
        updateLocationText(title) {
            this.locationText = title
        },
        clearSearch() {
            this.updateSearchOption({})
        },
        async searchDeal() {
            // if (this.locationText) {
            //     await this.updateSearchOption(
            //         null,
            //         0,
            //         'location',
            //         this.locationText
            //     )
            // }

            let keyword = ''

            const isTrainSearch = checkTrainSearch(this.currentSearchOption)
            const isLocationSearch = checkLocationSearch(
                this.currentSearchOption
            )

            if (isTrainSearch || isLocationSearch) {
                keyword = ''
            } else {
                keyword = this.currentSearchOption.q
            }

            let searchOption = {
                q: keyword,
                category: this.category === ' ' ? '' : this.category,
                location: this.locationText || this.currentSearch.location,
            }
            if (this.currentSearchOption.price) {
                const price = this.currentSearchOption.price
                if (price.min === 0.5 && price.max === 50.0) {
                    //
                } else {
                    searchOption.price = `${this.currentSearchOption.price.min}-${this.currentSearchOption.price.max}`
                }
            }
            if (this.trainSeach) {
                if (this.trainSeach.clear) {
                    searchOption = _.pickBy(
                        _.assign(
                            searchOption,
                            _.omit(
                                this.trainSeach,
                                'text',
                                'line',
                                'train',
                                'station',
                                'has_train'
                            )
                        ),
                        _.identity
                    )
                } else {
                    searchOption = _.pickBy(
                        _.assign(searchOption, _.omit(this.trainSeach, 'text')),
                        _.identity
                    )
                }
            } else {
                searchOption = _.assign(
                    searchOption,
                    _.pick(this.currentSearch, [
                        'line',
                        'train',
                        'station',
                        'has_train',
                    ])
                )
            }

            await this.updateSearchOption(searchOption)

            this.$emit('closed')
            this.doSearch()
        },
    },
}
