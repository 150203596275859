
import { useSearchable } from '../../composables'

// import RangeSlider from 'vue-range-slider'
import RangeSlider from '../RangeSlider'
// you probably need to import built-in style
import 'vue-range-slider/dist/vue-range-slider.css'

export default {
    components: {
        RangeSlider,
    },
    setup(props, { emit }) {
        const { currentSearch, updateSearchOption, currentSearchOption } =
            useSearchable(emit)

        return {
            currentSearch,
            updateSearchOption,
            currentSearchOption,
        }
    },
    data() {
        return {
            showPricePopup: false,
        }
    },
    mounted() {
        const vm = this
        this.$bus.$on('show-fixed-search', (flag) => {
            if (flag) {
                vm.showPricePopup = false
            }
        })
    },
    destroyed() {
        this.$bus.$off('show-fixed-search')
    },
    methods: {
        close() {
            this.showPricePopup = false
        },
        clear() {
            // this.updateSearchOption(null, 0, 'price', '0.50-50.00')
            this.updateSearchOption(null, 0, 'price', '')
            this.close()
        },
        accept($event) {
            this.updateSearchOption(
                null,
                0,
                'price',
                Number(this.currentSearchOption.price.min).toFixed(2) +
                    '-' +
                    Number($event).toFixed(2)
            )
        },
        togglePopup() {
            this.showPricePopup = !this.showPricePopup
        },
    },
}
