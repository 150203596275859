
import _ from 'lodash'
import { get } from 'vuex-pathify'
import SwiperGrid from '@/components/content/SwiperGrid.vue'
import GridItem from '../../components/content/GridItem'
import HeroTop from '../../components/content/DealHeroTop'
import HeroBottom from '../../components/content/DealHeroBottom'
import { buildSEO } from '../../common/utils'

const meta = {
    header: {
        title: 'รวมดีลเด็ด โปรปัง คอนโดติดรถไฟฟ้า จากอสังหาเจ้าดัง ครบครันทุกทำเล',
        description:
            'แหล่งรวมดีลเด็ดคอนโดติดรถไฟฟ้าทุกสถานีฮิต ทำเลฮอตในเขตกรุงเทพมหานคร และ ปริมณฑล พบดีลส่วนลดสุดปัง ประหยัดสูงสุดถึง 60% พร้อมโปรลด แลก แจก แถมอีกเพียบ เพราะ Propdeal รวบรวมโปรโมชั่นอัพเดตล่าสุดจากแบรนด์คอนโดยอดนิยมมาไว้ในที่เดียว! การันตีคุณภาพ คอนโดมือหนึ่ง ส่งตรงจาก Developer เจ้าดังมากมาย ครอบคลุมทั้งรถไฟฟ้า BTS, MRT และ Airport Link ทุกสาย เชื่อมต่อทุกการเดินทางให้คุณสะดวกสบาย',
    },
    footer: {
        title: 'รวมคอนโดติดรถไฟฟ้ากว่า 500 โครงการ',
        description:
            'แหล่งรวมโปรโมชั่นคอนโดติดรถไฟฟ้าทุกทำเลในเขตกรุงเทพมหานคร และ ปริมณฑล ดีลเด็ดทุกคอนโด เทียบได้ทุกโครงการ เพราะ Propdeal รวบรวมเอาคอนโดโปรดือมาไว้ในที่เดียว! แถมการันตีทุกโปรโมชั่นเด็ด อัพเดตทุกสัปดาห์ อย่าพลาด! ทุกดีลล่าสุดส่งตรงจาก Developer กว่า 500 โครงการคอนโดติดรถไฟฟ้าในทุกช่วงราคา และทุกทำเล เลือกรถไฟฟ้าที่ต้องการเพื่อค้นหาโปรเด็ดติดสายรถไฟฟ้าที่ตรงใจคุณ คลิกเลย!',
    },
    title: {
        title: 'โปรโมชั่นสุดร้อนแรง คอนโดติดรถไฟฟ้า!',
        subtitle:
            'รวมโปรโมชั่นคอนโดแบรนด์ดังสุดปังทั้งลด แลก แจก แถมที่ Propdeal คัดสรรมาเพื่อคุณจากทุกทำเลติดรถไฟฟ้า',
    },
    bts: {
        title: 'คอนโดติดรถไฟฟ้า BTS',
        subtitle:
            'รวมโปรโมชั่นคอนโดแบรนด์ดังสุดปังทั้งลด แลก แจก แถมที่ Propdeal คัดสรรมาเพื่อคุณจากทุกทำเลติดรถไฟฟ้า BTS',
    },
    mrt: {
        title: 'คอนโดติดรถไฟฟ้า MRT',
        subtitle:
            'รวมโปรโมชั่นคอนโดแบรนด์ดังสุดปังทั้งลด แลก แจก แถมที่ Propdeal คัดสรรมาเพื่อคุณจากทุกทำเลติดรถไฟฟ้า MRT',
    },
    arl: {
        title: 'คอนโดติดรถไฟฟ้า Airport Links',
        subtitle:
            'รวมโปรโมชั่นคอนโดแบรนด์ดังสุดปังทั้งลด แลก แจก แถมที่ Propdeal คัดสรรมาเพื่อคุณจากทุกทำเลติดรถไฟฟ้า Airport Links',
    },
}

export default {
    components: {
        GridItem,
        HeroTop,
        HeroBottom,
        SwiperGrid,
    },
    async asyncData({ store, query, route }) {
        try {
            const meta = route.meta.length > 0 ? route.meta[0] : {}

            const options = {}
            if (meta.category) {
                options.category = meta.category
            }

            const bts = await store.dispatch(
                'deals/search',
                _.assign(options, { train: 'BTS' })
            )
            const mrt = await store.dispatch(
                'deals/search',
                _.assign(options, { train: 'MRT' })
            )
            const arl = await store.dispatch(
                'deals/search',
                _.assign(options, { train: 'ARL' })
            )
            await store.dispatch('collection/load', 'promotions')

            await buildSEO(store, route, {
                name: 'Propdeal รวมโปรเด็ดราคาดี! คอนโดติดรถไฟฟ้า ใกล้รถไฟฟ้า BTS MRT',
                meta_description:
                    'รวมดีลสุดคุ้มคอนโดติดรถไฟฟ้า คอนโดใกล้รถไฟฟ้าราคาดีที่สุดเริ่มไม่ถึง 2 ล้าน พร้อมโปรโมชั่นฟรีโอน* ฟรีค่าส่วนกลาง* พร้อม Cashback สูงสุด 1 ล้านบาท* รีบจองตอนนี้ก่อนดีลดีๆจะหลุดมือ!',
                meta_keyword:
                    'คอนโดติดรถไฟฟ้า คอนโดใกล้รถไฟฟ้า คอนโดใกล้ BTS คอนโดใกล้MRT คอนโดใกล้แอร์พอร์ตลิงก์ คอนโด BTS คอนโด MRT คอนโดเริ่มไม่เกิน 2 ล้าน',
            })

            return {
                dealsNearBTS: bts,
                dealsNearMRT: mrt,
                dealsNearARL: arl,
                category: options.category,
            }
        } catch (err) {
            return {
                dealsNearBTS: [],
                dealsNearMRT: [],
                dealsNearARL: [],
                category: null,
            }
        }
    },
    data() {
        return {
            showMobileFilter: false,
            moreParams: {},
            metadata: meta,
        }
    },
    computed: {
        result: get('deals/result'),
        pagination: get('deals/pagination'),
        collections: get('collection/collection'),
        promotions() {
            const collection = this.collections.promotions
            return {
                title: collection ? collection.title : '',
                subtitle: collection ? collection.subtitle : '',
                items: _.map(collection ? collection.ads : [], (ads) => ({
                    name: ads.name,
                    image: ads.feature_image,
                    url: ads.url,
                })),
            }
        },
        query() {
            return this.$route.query
        },
        title() {
            const title = 'โครงการติดแนวรถไฟฟ้า'

            return title
        },
        displayOption() {
            return {
                location: false,
                train: true,
                trainFilterType: 'has_train',
            }
        },
    },
    methods: {
        openLink(i, item) {
            if (typeof item !== 'undefined') {
                window.location.href = item.url
            }
        },
        searchDeal(train) {
            const query = { train }
            if (this.category) {
                query.category = this.category
            }
            this.$router.push({
                path: '/deals',
                query,
            })
        },
    },
    head() {
        return {
            title: this.title,
        }
    },
}
